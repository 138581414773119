<template>
<div id="music-playlist" class="music">
    <div class="m-t-nav">
        <div class="big-m-t">
            
            <Playlist
            :playlist-position="0" 
            :playlist="listData"
            :listType="'songs'"
            :editButtons="false"
            :title="false"
            :subTitle="subTitle"
            :key="reloadComponent"
            :view="'list'"
            />

        </div>
    </div>
</div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import Playlist from '@/components/playlists/Playlist.vue'

export default {
  name: 'MusicPlaylist',
  components: {
    Playlist
  },
  data() {
	  return {
        listData: {
          songs: null
        },
        subTitle: null,
        reloadComponent: false
	  }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.getProfilesWorld()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'getProfilesWorld'
    
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ]),
  },
  methods: {
    getProfilesWorld() {
        if(this.$route.params.geoMatch) {
            const urlId = this.$route.params.geoMatch
            // this.subTitle = urlId
            rtdb.ref(`rankLocations/rankSongs/allUsers/${urlId}`).on('value', (snapshot) => {
                const arr = []
                const snapChild = snapshot.val()
                this.subTitle = snapshot.val().length + ' tracks'
                snapChild.forEach(x => {
                    const songSettings = x.songSettings
                    songSettings['rankTotal'] = x.rankTotal
                    songSettings['user'] = x.userSettings
                    arr.push(songSettings)
                })
                this.listData.songs = arr
                this.reloadComponent = !this.reloadComponent
            })
        }
        if(this.$route.params.genreMatch) {
            const urlId = this.$route.params.genreMatch
            // this.subTitle = urlId    
            rtdb.ref(`rankGenres/rankSongs/top10/${urlId}`).on('value', (snapshot) => {
                const arr = []
                const snapChild = snapshot.val()
                this.subTitle = snapshot.val().length + ' tracks'
                snapChild.forEach(x => {
                    const songSettings = x.songSettings
                    songSettings['rankTotal'] = x.rankTotal
                    songSettings['user'] = x.userSettings
                    arr.push(songSettings)
                })
                this.listData.songs = arr
                this.reloadComponent = !this.reloadComponent
            })
    

        }
    },
  }
  
}
</script>
<style lang="scss" scoped>
.music {
    // min-height: 100vh;
}
</style>